import React from "react";
import { useParams } from "react-router-dom";
import { SecondaryButton } from "../shared/Button";
import { Link } from "react-router-dom";

const images = {
  card8: [
    "https://citibatiment.s3.eu-west-3.amazonaws.com/salle-de-bain/1.JPG",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/salle-de-bain/2.JPG",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/salle-de-bain/3.JPG",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/salle-de-bain/5.JPG",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/salle-de-bain/4.JPG",

  ],
  card7: [
    "https://citibatiment.s3.eu-west-3.amazonaws.com/mani/1.JPG",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/mani/2.JPG",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/mani/3.JPG",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/mani/4.JPG",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/mani/5.JPG",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/mani/6.JPG",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/mani/7.JPG",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/mani/8.JPG",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/mani/9.JPG",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/mani/10.JPG",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/mani/11.JPG",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/mani/12.JPG",

  ],
  card6: [
    "https://citibatiment.s3.eu-west-3.amazonaws.com/cassin/1.JPG",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/cassin/2.JPG",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/cassin/3.JPG",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/cassin/4.JPG",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/cassin/5.JPG",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/cassin/6.JPG",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/cassin/7.JPG",
  ],
  // mercuri: [
  //   "https://citibatiment.s3.eu-west-3.amazonaws.com/mercuri/1.JPG",
  //   "https://citibatiment.s3.eu-west-3.amazonaws.com/mercuri/2.JPG",
  //   "https://citibatiment.s3.eu-west-3.amazonaws.com/mercuri/3.JPG",
  // ],
  card5: [
    "https://citibatiment.s3.eu-west-3.amazonaws.com/xihong/1.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/xihong/2.JPG",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/xihong/3.jpg"
  ],
  card1: [
    "https://citibatiment.s3.eu-west-3.amazonaws.com/av-roi-albert/2.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/av-roi-albert/4.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/av-roi-albert/6.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/av-roi-albert/7.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/av-roi-albert/10.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/av-roi-albert/11.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/av-roi-albert/13.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/av-roi-albert/14.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/av-roi-albert/18.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/av-roi-albert/19.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/av-roi-albert/20.jpg",
  ],
  // card3: [
  //   "https://citibatiment.s3.eu-west-3.amazonaws.com/bd-eugene-gazagnaire/1.jpg",
  //   "https://citibatiment.s3.eu-west-3.amazonaws.com/bd-eugene-gazagnaire/2.jpg",
  //   "https://citibatiment.s3.eu-west-3.amazonaws.com/bd-eugene-gazagnaire/3.jpg",
  //   "https://citibatiment.s3.eu-west-3.amazonaws.com/bd-eugene-gazagnaire/4.jpg",
  //   "https://citibatiment.s3.eu-west-3.amazonaws.com/bd-eugene-gazagnaire/5.jpg",
  //   "https://citibatiment.s3.eu-west-3.amazonaws.com/bd-eugene-gazagnaire/6.jpg",
  //   "https://citibatiment.s3.eu-west-3.amazonaws.com/bd-eugene-gazagnaire/7.jpg",
  //   "https://citibatiment.s3.eu-west-3.amazonaws.com/bd-eugene-gazagnaire/8.jpg",
  //   "https://citibatiment.s3.eu-west-3.amazonaws.com/bd-eugene-gazagnaire/9.jpg",
  //   "https://citibatiment.s3.eu-west-3.amazonaws.com/bd-eugene-gazagnaire/10.jpg",
  //   "https://citibatiment.s3.eu-west-3.amazonaws.com/bd-eugene-gazagnaire/11.jpg",
  //   "https://citibatiment.s3.eu-west-3.amazonaws.com/bd-eugene-gazagnaire/12.jpg",
  //   "https://citibatiment.s3.eu-west-3.amazonaws.com/bd-eugene-gazagnaire/13.jpg",
 
  // ],
  // card4: [
  //   "https://citibatiment.s3.eu-west-3.amazonaws.com/av-saint-jean/1.jpg",
  //   "https://citibatiment.s3.eu-west-3.amazonaws.com/av-saint-jean/2.jpg",
  //   "https://citibatiment.s3.eu-west-3.amazonaws.com/av-saint-jean/3.jpg",
  //   "https://citibatiment.s3.eu-west-3.amazonaws.com/av-saint-jean/4.jpg",
  //   "https://citibatiment.s3.eu-west-3.amazonaws.com/av-saint-jean/5.jpg",
  //   "https://citibatiment.s3.eu-west-3.amazonaws.com/av-saint-jean/6.jpg",
  //   "https://citibatiment.s3.eu-west-3.amazonaws.com/av-saint-jean/7.jpg",
  //   "https://citibatiment.s3.eu-west-3.amazonaws.com/av-saint-jean/8.jpg",
  //   "https://citibatiment.s3.eu-west-3.amazonaws.com/av-saint-jean/9.jpg",
  //   "https://citibatiment.s3.eu-west-3.amazonaws.com/av-saint-jean/10.jpg",
  //   "https://citibatiment.s3.eu-west-3.amazonaws.com/av-saint-jean/11.jpg",
  //   "https://citibatiment.s3.eu-west-3.amazonaws.com/av-saint-jean/12.jpg",

  // ]
};

function House() {
  let { topicId } = useParams();

  let image = images[topicId].map((image) => {
    return (
      <div className="mt-5 col-sm-12 col-md-6 col-lg-4 d-flex justify-content-center mb-3">
        <div className="">
          <img src={image} className="card-img-top rounded-0" alt="portofolio"/>
        </div>
      </div>
    );
  });

  return (
    <div className="container">
      <div className="mt-5 pt-5 d-flex align-self-end">
        <Link to="/portofolio">
          <SecondaryButton text='Back'/>
        </Link>
      </div>
      <div className="row">
        {image}
      </div>
    </div>
  );
}

export default House;