import React from "react"
import BlogCard from "./blogCard";
const myBlogs = [
    {
        id: "1",
        title: "Comment créer un design d'intérieur personnalisé pour toute la famille",
        author: 'Tudor Cheibas',
        date: "Septembre 20, 2023",
    }
];

export default function BlogPage(props) {
    const blogItems = myBlogs.map(blog => <BlogCard data={blog} ></BlogCard>)
    return (
        <div className="mt-5 pt-5 d-flex container-md  mb-5 pb-5">
            <div className="row justify-content-evenly">
             {blogItems}
            </div>
        </div>
    );
}