import React, { Component } from "react";
import plumber from "./images/plumber.jpg";
import vopsit from "./images/vopsit.jpg";
import electricity from "./images/ele.jpg";
import marmura from "./images/marmura.jpg";
import plastering from "./images/soundproofing.jpg";
import "./services.css";

function ServiceCard({ title, src, content }) {
  return (
    <div className="col-sm-12 col-md-6 col-lg-4 d-flex justify-content-center mb-3">
      <div className="service-card rounded-0 p-4">
        <div className="d-flex justify-content-center">
          <img
            className="card-img-top rounded-0 p-3"
            src={src}
            alt="Card marble"
            // style={{"maxWidth": "200px"}}
          />
        </div>
        <div className="card-body">
          <h3 className="mb-3">{title}</h3>
          <div className="d-flex text-justify">{content}</div>
        </div>
      </div>
    </div>
  );
}
class ServicesDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otherServices: "",
      otherServicesText: {
        clim: "",
        kitchen: "",
        maconeri: "",
        pvc: "",
        parquet: "",
      },
      electricity: "",
      electricityText: "",
      plumber: "",
      plumberText: "",
      ceiling: "",
      ceilingText: "",
      painting: "",
      paintingText: "",
      carrelage: "",
      carrelageText: "",
    };
  }
  componentWillReceiveProps({ data }) {
    this.setState({
      ...data,
    });
  }
  componentDidMount() {
    this.setState({
      ...this.props.data,
    });
  }

  render() {
    return (
      <div style={{ backgroundColor: "#FAFAFA" }}>
        <div className="pt-5">
          <div className="container p-3">
            <div className="row">
              <ServiceCard
                title={this.state.electricity}
                src={electricity}
                content={this.state.electricityText}></ServiceCard>
              <ServiceCard
                title={this.state.plumber}
                src={plumber}
                content={this.state.plumberText}></ServiceCard>
              <ServiceCard
                title={this.state.carrelage}
                src={marmura}
                content={this.state.carrelageText}></ServiceCard>
              <ServiceCard
                title={this.state.plaquiste}
                src={plastering}
                content={this.state.plaquisterieText}></ServiceCard>
              <ServiceCard
                title={this.state.painting}
                src={vopsit}
                content={this.state.paintingText}></ServiceCard>
            </div>
            <h2 className="mt-3 p-3">{this.state.premiumPrestations}</h2>
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6  d-flex justify-content-center mb-3">
                <div className="other-service-card rounded-0 p-4 ">
                  <div className="card-body">
                    <h3 className="mb-3">{this.state.fitwall}</h3>
                    <div className="d-flex text-justify">
                      {this.state.fitwallText}
                    </div>
                    <a href="https://www.krion.com/fr/nouvelles/krion- fitwall">
                      Krion Fitwall
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6  d-flex justify-content-center mb-3">
                <div className="other-service-card rounded-0 p-4 ">
                  <div className="card-body">
                    <h3 className="mb-3">{this.state.xtone}</h3>
                    <div className="d-flex text-justify">
                      {this.state.xtoneText}
                    </div>
                    <a href="https://www.porcelanosa.com/trendbook/fr/porcelanosa-salle-de-bain-grand-format-xtone/">
                      XTone
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ServicesDetails;
