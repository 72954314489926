import React, { Component } from "react";
import about1 from "./firstAbout.jpg";
import about2 from "./secondAbout.jpg";

import "./about.css";

class AboutDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstPart: "",
      secondPart: "",
    };
  }
  componentWillReceiveProps({ data }) {
    this.setState({
      ...data,
    });
  }
  componentDidMount() {
    this.setState({
      ...this.props.data,
    });
  }
  render() {
    return (
        <div className="d-flex align-items-center justify-content-center bg-light pt-5">
          <div className="container row d-flex align-items-center bg-white  mb-5 text-left">
            <div className="row p-5">
              <div className="col">
                <img
                  src={about1}
                  alt="About Citibatiment"
                  className="img-fluid"
                />
              </div>
              <div className="col-lg d-flex row p-5 align-items-center">
                <div className="w-100">
                  <h3 className="text-center">About us</h3>
                </div>
                <pre className="about-text">{this.state.firstPart}</pre>
              </div>
            </div>
            <div className="row p-5">
              <div className="col-lg d-flex row p-5 align-items-center">
                <pre className="about-text">{this.state.secondPart}</pre>
              </div>
              <div className="col">
                <img
                  src={about2}
                  alt="About Citibatiment"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default AboutDetails;
