import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./portofolioWorks.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const worksPortofolio = [
  {
    id: 5,
    location: "Cannes",
    topicId: "card5",
    imgSrc: "https://citibatiment.s3.eu-west-3.amazonaws.com/xihong/1.jpg",
  },
  {
    id: 8,
    location: "Cannes",
    topicId: "card7",
    imgSrc: "https://citibatiment.s3.eu-west-3.amazonaws.com/mani/10.JPG",
  },
  {
    id: 7,
    location: "Cannes",
    topicId: "card6",
    imgSrc: "https://citibatiment.s3.eu-west-3.amazonaws.com/cassin/4.JPG",
  },
  {
    id: 9,
    location: "Cannes",
    topicId: "card8",
    imgSrc:
      "https://citibatiment.s3.eu-west-3.amazonaws.com/salle-de-bain/2.JPG",
  },
  
  {
    id: 0,
    location: "Cannes, Avenue Roi Albert 1er",
    topicId: "card1",
    imgSrc:
      "https://citibatiment.s3.eu-west-3.amazonaws.com/av-roi-albert/18.jpg",
  },
  // {
  //   id: 1,
  //   location: "Cannes",
  //   topicId: "card3",
  //   imgSrc:
  //     "https://citibatiment.s3.eu-west-3.amazonaws.com/bd-eugene-gazagnaire/7.jpg",
  // },
  // {
  //   id: 1,
  //   location: "Cannes",
  //   topicId: "card4",
  //   imgSrc:
  //     "https://citibatiment.s3.eu-west-3.amazonaws.com/av-saint-jean/8.jpg",
  // },
];

function WorkCard({ topicId, location, imgSrc }) {
  return (
    <div className="col-sm-12 col-md-6 col-lg-4 d-flex justify-content-center mb-3">
      <div className="service-card mb-5">
        <Link to={`/portofolio/${topicId}`}>
          <img
            className="card-img-top rounded-0"
            src={imgSrc}
            alt="Card marble"
            id="card1"
          />
        </Link>
        <div className="card-body d-flex">
        <FontAwesomeIcon
                  icon={["fas", "map"]}
                  size="x"
                  className="mr-3"
                  color="#cecece"
                /> 
          <h6 className=""> 
          {location}
          </h6>
        </div>
      </div>
    </div>
  );
}

class PortofolioPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      topicId: "",
    };
  }
  setTopicId = (e) => {
    this.setState({
      topicId: e.target.id,
    });
  };

  render() {
    return (
      <div className="pt-5 works-page">
        <div className="container p-3 mt-5">
          <div className="row mt-5 mb-5">
            {worksPortofolio.map((work) => (
              <WorkCard {...work} />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default PortofolioPage;
