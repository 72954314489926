import React, { useState } from "react";
import "./menu.css";
import { Link } from "react-router-dom";

function Menu (props) {
  const [showMenu, setShowMenu] = useState(false);

  const handleToggleMenu = (e) => {
    e.preventDefault();
    setShowMenu( (prev) => { return !prev})
  };

  function collapseMenu() {
    setShowMenu(false);
  }


  const isMobile = navigator.userAgent.match(/Android/i)
         || navigator.userAgent.match(/webOS/i)
         || navigator.userAgent.match(/iPhone/i)
         || navigator.userAgent.match(/iPad/i)
         || navigator.userAgent.match(/iPod/i)
         || navigator.userAgent.match(/BlackBerry/i)
         || navigator.userAgent.match(/Windows Phone/i);

    return (
      <div className="navDiv">
        <div className="topnav fixed-top d-flex align-items-center height100" id="main-nav">
          <div className="container d-flex flex-row  justify-content-between align-items-center p-0">
            <Link to="/" className="active pl-0 pr-0">
              <div className="text-left container text-big p-0">CitiBatiment</div>
            </Link>
            {isMobile ? (
              <div className="d-flex flex-row align-items-center">
                <a
                  href="/"
                  className="navbar-toggler"
                  data-toggle="collapse"
                  data-target="#navBarToggle"
                  onClick={(e) => handleToggleMenu(e)}>
                  <i className="fa fa-bars"></i>
                </a>
              </div>
            ) : (
              <div className="container d-flex flex-row justify-content-end">
                <Link to="/about" className=" pl-0 pr-0">
                  <div className="text-left container text">{props.data.about}</div>
                </Link>
                <Link to="/services" className=" pl-0 pr-0">
                  <div className="text-left container text">{props.data.services}</div>
                </Link>
                <Link to="/portofolio" className=" pl-0 pr-0">
                  <div className="text-left container text">{props.data.portofolio}</div>
                </Link>
                <Link to="/blog" className="pl-0 pr-0">
                  <div className="text-left container text">{props.data.blog}</div>
                </Link>
                <Link to="/contact" className=" pl-0 pr-0">
                  <div className="text-left container text">{props.data.contact}</div>
                </Link>
              </div>
            )}
          </div>
        </div>
        <div
          className={
            showMenu
              ? "d-block collapse topnav mt100 p-0"
              : "d-none collapse topnav mt100"
          }
          id="navBarToggle">
          <div className="container m-auto row row-cols-1 row-cols-sm-1 row-cols-md-4 menu">
            <Link
              to="/about"
              className="col"
              onClick={(e) => collapseMenu()}>
              {props.data.about}
            </Link>
            <Link
              to="/services"
              className="col"
              onClick={(e) => collapseMenu()}>
              {props.data.services}
            </Link>
            <Link
              to="/portofolio"
              className="col"
              onClick={(e) => collapseMenu()}>
              {props.data.portofolio}
            </Link>
            <Link
              to="/blog"
              className="col"
              onClick={(e) => collapseMenu()}>
              {props.data.blog}
            </Link>
            <Link
              to="/contact"
              className="col"
              onClick={(e) => collapseMenu()}>
              {props.data.contact}
            </Link>
          </div>
        </div>
      </div>
    );
}

export default Menu;
