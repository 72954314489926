import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./portofolio.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PrimaryButton } from "../shared/Button";

function Portofolio(props) {
  const [scroll, setScroll] = useState(false);
  const [rightIndex, setRightIndex] = useState(2);
  const [activeIndex, setActiveIndex] = useState(1);
  const [leftIndex, setLeftIndex] = useState(0);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 1800);
    });
  }, [scroll]);

  const images =  [
    "https://citibatiment.s3.eu-west-3.amazonaws.com/xihong/2.JPG",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/mani/11.JPG",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/mani/12.JPG",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/mani/8.JPG",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/xihong/1.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/xihong/3.jpg"
  ];

  const moveIndexToRight = (toRight) => {
    if (toRight) {
      // we are at the end
      if (rightIndex === images.length - 1) {
        setLeftIndex((prev) => prev + 1);
        setActiveIndex((prev) => prev + 1);
        setRightIndex(0);
      } else if (
        activeIndex === images.length - 1
      ) {
        setLeftIndex((prev) => prev + 1);
        setRightIndex((prev) => prev + 1);
        setActiveIndex(0);
      } else if ( leftIndex === images.length - 1) {
        setLeftIndex(0);
        setRightIndex((prev) => prev + 1);
        setActiveIndex((prev) => prev + 1);
      } else {
        setLeftIndex((prev) => prev + 1);
        setRightIndex((prev) => prev + 1);
        setActiveIndex((prev) => prev + 1);
      }
    } else {
      if (leftIndex === 0) {
        setLeftIndex(images.length - 1);
        setActiveIndex((prev) => prev - 1);
        setRightIndex((prev) => prev - 1);
      } else if ( rightIndex === 0) {
        setRightIndex(images.length - 1);
        setActiveIndex((prev) => prev - 1);
        setLeftIndex((prev) => prev - 1);
      } else if ( activeIndex  === 0) {
        setActiveIndex(images.length - 1);
        setRightIndex((prev) => prev - 1);
        setLeftIndex((prev) => prev - 1);
      } else {
        setActiveIndex((prev) => prev - 1);
        setRightIndex((prev) => prev - 1);
        setLeftIndex((prev) => prev - 1);
      }
    }
  }
  return (
    <div>
      <div className="d-flex row-md align-items-center justify-content-center pt-5 pb-5">
        <button className="btn" onClick={() => moveIndexToRight(false)}>
            <FontAwesomeIcon
                  icon={["fas", "chevron-left"]}
                  size="2x"
                  className="mr-2"
                />
        </button>
        <div className="d-flex align-items-center justify-content-center">
          <img
            src={images[leftIndex]}
            className="left-image d-none d-md-block"
            loading="lazy"
            alt="..."
          />
          <img
            src={images[activeIndex]}
            className="portofolio-image"
            loading="lazy"
            alt="..."
          />
          <img
            src={images[rightIndex]}
            className="right-image d-none d-md-block"
            loading="lazy"
            alt="..."
          />
        </div>
        <button className="btn" onClick={() => moveIndexToRight(true)}> 
        <FontAwesomeIcon
                  icon={["fas", "chevron-right"]}
                  size="2x"
                  className="mr-2"
                />
         </button>
      </div>
      <Link to="/portofolio">
        <PrimaryButton text={props.data.viewPortofolio} className={"mb-3"}/>
      </Link>
    </div>
  );
}

export default Portofolio;
